<template>
  <VList
    :hide-header="true"
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :name="item.displayed_name" :color="item.colour" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.default_submit_by="{ item }">
      {{ $t(`app.${item.default_submit_by}`) }}
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="cursor-pointer" @click="onClickOverview(item)">
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_name }}
        </div>
        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          {{ item.displayed_details }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-center">
        <VAction
          :disabled="isUnauthorized(item)"
          :label="getActionLabel(item, 'edit')"
          icon="edit"
          @click="onClickUpdateDetails(item.id)"
        />

        <VDeleteAction
          :disabled="isUnauthorized(item)"
          :label="getActionLabel(item, 'delete')"
          :item="item"
          :text-value="item.displayed_name"
          :delete-function="onClickDelete"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useAttestation from "@/composables/useAttestation";
import useRead from "@/composables/useRead";
import useSysAdminLock from "@/composables/useSysAdminLock";
// Components
import VList from "@/components/tables/VList";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";
import VDeleteAction from "@/components/VDeleteAction";
import VChip from "@/components/VChip";

export default {
  components: {
    VDeleteAction,
    VList,
    VAction,
    VImage,
    VChip
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const documentTitle = `${t("app.attestations", 2)} - ${t("app.teaching")}`;

    // COMPUTED
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_name: getText(row.texts, "name"),
        displayed_department: row.department?.name || "",
        displayed_details: trimHTML(getText(row.texts, "details")),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });

    // CUSTOM COMPOSABLES
    const { getText } = useTexts();
    const { isUnauthorized, getActionLabel } = useSysAdminLock("lesson");
    const { trimHTML } = useDisplay();
    const { getStatusColor } = useColor();
    const { endpoint, route, types, searchFields, headers } = useAttestation();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      onClickUpdateDetails
    } = useRead({
      endpoint,
      route,
      relations: ["texts", "department"],
      types,
      searchFields
    });

    // METHODS
    const onClickOverview = item => {
      if (isUnauthorized(item)) {
        return;
      }

      onClickUpdate(item.id);
    };

    return {
      documentTitle,
      displayedRows,
      onClickUpdateDetails,
      onClickOverview,
      // useAttestation
      headers,
      // useColor
      getStatusColor,
      // useSysAdminLocked
      getActionLabel,
      isUnauthorized,
      // useRead
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      rows,
      isLoading
    };
  }
};
</script>
